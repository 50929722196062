<template>
  <el-dialog
    class="dialog"
    width="1050rem"
    :visible.sync="dialogVisible"
    :show-close="false"
  >
    <i v-if="length > 1" class="el-icon-arrow-left" @click="dialogPrev"></i>
    <i v-if="length > 1" class="el-icon-arrow-right" @click="dialogLower"></i>
    <i class="el-icon-close" @click="CloseDialogVisible"></i>
    <div class="flex-col dialog-title">
      <span>来自 {{ videoData.dialogTitle }} 的投放</span>
      <span>最新投放时间：{{ videoData.beginUnix | dateFormat }}</span>
    </div>
    <div v-if="videoData.currentScreen" :class="[videoData.currentScreen]" class="align-center">
      <video
        ref="videoRef"
        :src="videoData.dialogUrl"
        controls
        autoplay
        class="video"
        style="object-fit: contain"
      ></video>
    </div>
    <div v-else class="previewDialog align-center">
      <video
        ref="videoRef"
        :src="videoData.dialogUrl"
        controls
        autoplay
        class="video"
        style="object-fit: contain"
      ></video>
    </div>
  </el-dialog>
</template>

<script>
/* eslint-disable vue/require-valid-default-prop */

export default {
  props: {
    videoData: {
      type: Object,
      default: {},
      required: true,
    },
    length: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        const ElementVideo = this.$refs.videoRef;
        if (!ElementVideo.paused) {
          ElementVideo.pause();
        }
      }
    },
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    // 打开弹窗
    openDialog() {
      this.dialogVisible = true;
    },
    CloseDialogVisible() {
      this.dialogVisible = false;
      const videoElement = this.$refs.videoRef;
      videoElement.pause();
    },
    dialogPrev() {
      this.$emit("dialogPrev");
    },
    dialogLower() {
      this.$emit("dialogLower");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .el-icon-arrow-left {
    position: absolute;
    top: calc(50% - 23rem);
    left: -15%;
    color: #fff;
    font-size: 46rem;
    cursor: pointer;
  }
  .el-icon-arrow-right {
    position: absolute;
    top: calc(50% - 23rem);
    right: -15%;
    color: #fff;
    font-size: 46rem;
    cursor: pointer;
  }
  .el-icon-close {
    position: absolute;
    top: 19rem;
    right: 20rem;
    color: #fff;
    font-size: 36rem;
    cursor: pointer;
    z-index: 9;
  }
  .dialog-title {
    width: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0.56) 0%, rgba(255,255,255,0) 100%);
    padding: 16rem 24rem;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
  }
  .previewDialog {
    width: 100%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    .video {
      width: 100%;
      height: 592rem;
    }
  }
  .default {
    width: 100%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    .video {
      width: 100%;
      height: 700rem;
    }
  }
  .yihua,.demo {
    width: 100%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    .video {
      width: 100%;
      height: 788rem;
    }
  }
  .xinghu {
    width: 100%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    .video {
      width: 100%;
      height: 532rem;
    }
  }
}

::v-deep .el-dialog__body {
  padding: 0;
  background: #000;
}
::v-deep .el-dialog__header {
  padding: 0;
}
</style>
