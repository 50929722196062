<template>
  <el-dialog
    class="dialog"
    width="1050rem"
    :visible.sync="dialogVisible"
    :show-close="false"
  >
    <i class="el-icon-close" @click="CloseDialogVisible"></i>
    <div
      v-if="videoData.dialogTitle && videoData.dialogTitle == '航拍地段'"
      class="dialog-title"
    >
      <span>航拍地段</span>
    </div>
    <div v-if="videoData.currentScreen" :class="[videoData.currentScreen]" class="align-center">
      <video
        ref="videoRef"
        :src="videoData.dialogUrl"
        controls
        autoplay
        :muted="videoData.muted || false"
        class="video"
        style="object-fit: contain"
      ></video>
    </div>
    <div v-else class="previewDialog align-center">
      <video
        ref="videoRef"
        :src="videoData.dialogUrl"
        controls
        autoplay
        :loop="videoData.dialogTitle && videoData.dialogTitle == '航拍地段'"
        :muted="videoData.muted || false"
        class="video"
        style="object-fit: contain"
      ></video>
    </div>
  </el-dialog>
</template>

<script>
/* eslint-disable vue/require-valid-default-prop */

export default {
  props: {
    videoData: {
      type: Object,
      default: {},
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {
    dialogVisible(val) {
      const videoElement = this.$refs.videoRef;
      if (!val && videoElement) {
        videoElement.pause();
      }
    },
  },
  methods: {
    // 打开弹窗
    openDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        const videoElement = this.$refs.videoRef;
        // 判断视频是否暂停
        if (videoElement.paused) {
          // 如果视频暂停了，重新播放视频
          videoElement.play();
        }
      });
    },
    CloseDialogVisible() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .el-icon-close {
    position: absolute;
    // top: -25%;
    // right: -38%;
    top: 24rem;
    right: 24rem;
    color: #fff;
    font-size: 36rem;
    cursor: pointer;
    z-index: 9;
  }
  .dialog-title {
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.56) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 24rem 24rem;
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
  }
  .previewDialog {
    width: 100%;
    background-color: #000;
    .video {
      width: 100%;
      height: 592rem;
    }
  }
  .default {
    width: 100%;
    background-color: #000;
    .video {
      width: 100%;
      height: 700rem;
    }
  }

  .yihua,.demo {
    width: 100%;
    background-color: #000;
    .video {
      width: 100%;
      height: 788rem;
    }
  }
  .xinghu {
    width: 100%;
    background-color: #000;
    .video {
      width: 100%;
      height: 532rem;
    }
  }
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}

::v-deep .el-dialog__body {
  padding: 0;
  background: #000;
}
::v-deep .el-dialog__header {
  padding: 0;
}
</style>
