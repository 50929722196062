<template>
  <div class="page flex-col">
    <div
      :class="{ banner_Bg: true, noDaping: aliveScreenList.length == 0 }"
      :style="newDiscountStatus == 0 || !token ? 'top:-79rem' : 'top:-70rem'"
    >
      <img src="@/assets/img/banner_Bg.png" alt="" />
    </div>
    <div class="banner flex-col align-center">
      <div class="txt1-view flex-row align-center">
        直接投放广告<span class="txt">无中介 </span>
      </div>
      <span class="txt2">一款针对LED大屏的广告自助投放软件 </span>
      <div class="launch-btn flex-col" id="primary-btn" @click="onClick_create">
        立即体验
      </div>
      <div class="adSpec" @click="goWhyPage">为什么要投大屏广告？</div>
    </div>
    <div v-if="isOpen && aliveScreenList.length" class="hlsVideo">
      <div class="screenList flex-row align-center">
        <div
          v-for="(item, index) in aliveScreenList"
          :key="index"
          :class="{
            screenItem: true,
            active:
              aliveCurrentScreenName == item.name ||
              (item.attachSub.length &&
              item.attachSub[0].name == aliveCurrentScreenName
                ? true
                : false),
          }"
          @click="changeAliveScreen(item.name)"
        >
          <span>{{ item.remark }}</span>
          <img
            v-if="
              aliveCurrentScreenName == aliveScreenList[0].name &&
              (aliveCurrentScreenName == item.name ||
                (item.attachSub.length &&
                item.attachSub[0].name == aliveCurrentScreenName
                  ? true
                  : false))
            "
            class="hlsVideo-tab1"
            src="@/assets/img/hlsVideo-tab1.png"
            alt=""
          />
          <img
            v-if="
              aliveCurrentScreenName != aliveScreenList[0].name &&
              (aliveCurrentScreenName == item.name ||
                (item.attachSub.length &&
                item.attachSub[0].name == aliveCurrentScreenName
                  ? true
                  : false))
            "
            class="hlsVideo-tab2"
            src="@/assets/img/hlsVideo-tab2.png"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="
          currentScreenInfo.isMain &&
          currentScreenInfo.attachSub &&
          currentScreenInfo.attachSub.length
        "
        @click="changeAliveScreen(currentScreenInfo.attachSub[0].name)"
        class="change-view justify-center align-center"
      >
        <img src="@/assets/icon/exchange.png" alt="" />
        <span>切至辅屏</span>
      </div>
      <div
        v-else-if="!currentScreenInfo.isMain"
        @click="changeAliveScreen(currentScreenInfo.attachMainName)"
        class="change-view justify-center align-center"
      >
        <img src="@/assets/icon/exchange.png" alt="" />
        <span>切至主屏</span>
      </div>
      <div v-else class="change-view justify-center align-center"></div>
      <div class="hlsVideo-title flex-row justify-between align-center">
        <div class="flex-left flex-row align-center">
          <img
            v-if="isCloseScreen"
            src="../../assets/img/shut_own.png"
            alt=""
          />
          <img v-else-if="isOff" src="../../assets/img/screen_off.png" alt="" />
          <img v-else src="../../assets/img/hls_icon.png" alt="" />
          <div v-if="isCloseScreen">
            <span v-if="currentScreenInfo.attachMainName"
              >{{
                currentScreenInfo.attachMainName | screenFilter
              }}（辅屏），大屏幕已关机</span
            >
            <span v-else
              >{{ currentScreenInfo.name | screenFilter }}，大屏幕已关机</span
            >
          </div>
          <div v-else-if="isOff">
            <span v-if="currentScreenInfo.attachMainName"
              >{{
                currentScreenInfo.attachMainName | screenFilter
              }}（辅屏），大屏幕已断网</span
            >
            <span v-else
              >{{ currentScreenInfo.name | screenFilter }}，大屏幕已断网</span
            >
          </div>
          <div v-else-if="alive">
            <span v-if="currentScreenInfo.attachMainName"
              >{{
                currentScreenInfo.attachMainName | screenFilter
              }}（辅屏），正在播放中...</span
            >
            <span v-else
              >{{ currentScreenInfo.name | screenFilter }}，正在播放中...</span
            >
          </div>
          <div v-else-if="currentScreenInfo.name == 'demo'">
            <span
              >{{ currentScreenInfo.name | screenFilter }}，正在播放中...</span
            >
          </div>
          <div v-else>
            <span v-if="currentScreenInfo.attachMainName"
              >{{
                currentScreenInfo.attachMainName | screenFilter
              }}（辅屏），正在加载中...</span
            >
            <span v-else
              >{{ currentScreenInfo.name | screenFilter }}，正在加载中...</span
            >
          </div>
        </div>
        <div class="flex-row align-center">
          <div
            v-if="currentScreenInfo.video"
            class="popover"
            @click="onClick_see"
          >
            <div class="reference flex-row align-center">
              <svg-icon class="svg" iconClass="UAV"></svg-icon>
            </div>
          </div>
          <el-popover
            class="popover"
            placement="top"
            trigger="click"
            :visible-arrow="false"
            popper-class="homePopover2"
          >
            <div class="device-info flex-row flex-wrap">
              <div class="info-item flex-row align-center">
                <span class="label">设备名称：</span>
                <span class="value">{{ currentScreenInfo.remark }}</span>
              </div>
              <div class="info-item flex-row align-center">
                <span class="label">设备类型：</span>
                <span class="value">LED大屏</span>
              </div>
              <div class="info-item flex-row align-center">
                <span class="label">屏幕分辨率：</span>
                <span class="value"
                  >{{ currentScreenInfo.resolutionWidth }}*{{
                    currentScreenInfo.resolutionHeight
                  }}
                  (即{{ aspectRatioFn() }})</span
                >
              </div>
              <div class="info-item flex-row align-center">
                <span class="label">开机时间：</span>
                <span class="value">{{ openTimeFn() }}</span>
              </div>
              <div
                v-if="currentScreenInfo.peopleTraffic"
                class="info-item flex-row align-center"
              >
                <span class="label">人流量：</span>
                <span class="value">{{ currentScreenInfo.peopleTraffic }}</span>
              </div>
              <div class="info-item flex-row align-center">
                <span class="label">广告展现方式：</span>
                <span class="value">图片/视频/声音</span>
              </div>
              <div
                v-if="currentScreenInfo.carTraffic"
                class="info-item flex-row align-center"
              >
                <span class="label">车流量：</span>
                <span class="value">{{ currentScreenInfo.carTraffic }}</span>
              </div>
              <div
                v-if="currentScreenInfo.size"
                class="info-item flex-row align-center"
              >
                <span class="label">屏幕尺寸：</span>
                <span class="value">{{ currentScreenInfo.size }}</span>
              </div>
              <div
                v-if="currentScreenInfo.audience"
                class="info-item flex-row long-line"
              >
                <span class="label">受众人群：</span>
                <span class="value">{{ currentScreenInfo.audience }}</span>
              </div>
              <div
                v-if="currentScreenInfo.address"
                class="info-item flex-row long-line"
              >
                <span class="label">设备地址：</span>
                <span class="value">{{ currentScreenInfo.address }}</span>
              </div>
            </div>
            <div slot="reference" class="reference flex-row align-center">
              <svg-icon class="svg" iconClass="sigh"></svg-icon>
            </div>
          </el-popover>
          <el-popover
            v-if="alive"
            class="popover"
            placement="top"
            trigger="click"
            :visible-arrow="false"
            popper-class="homePopover"
          >
            <div class="popover-content flex-col justify-between">
              <span>1、当前播放内容为实景模拟直播，不是由摄像头直接拍摄。</span>
              <span
                >2、播放效果、播放器尺寸及播放内容，都与大屏幕当前的实际情况完全一致。</span
              >
              <span
                >3、当前播放内容是由大屏控制端软件，实时截取大屏幕正在投放的内容，
                并推流到服务器，然后再在当前网页上进行显示，以此达到实时同步的效果，使当前播放画面与实际情况完全一致。</span
              >
            </div>
            <div slot="reference" class="reference flex-row align-center">
              <svg-icon class="svg" iconClass="homeOverlay"></svg-icon>
            </div>
          </el-popover>
          <div v-if="!isCloseScreen" class="popover" @click="onClick_p2p">
            <div class="reference flex-row align-center">
              <svg-icon class="svg" iconClass="video"></svg-icon>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isCloseScreen"
        :class="['video-view', aliveCurrentScreenName]"
        :style="'background-image: url(' + currentScreenInfo.image + ')'"
      >
        <div
          class="seat-view off-close-view flex-col justify-center align-center"
        >
          <img src="../../assets/img/shut_own.png" alt="" />
          <span class="span1">大屏幕已关机</span>
          <span class="span2"
            >该屏幕因天气、故障等不可抗力因素被设置成关机状态，若给您投放广告带来不便，我们深感抱歉！</span
          >
        </div>
      </div>
      <div
        v-else-if="isOff"
        :class="['video-view', aliveCurrentScreenName]"
        :style="'background-image: url(' + currentScreenInfo.image + ')'"
      >
        <div
          class="seat-view off-close-view flex-col justify-center align-center"
        >
          <img
            src="../../assets/img/screen_off.png"
            style="width: 70rem; height: 46rem"
            alt=""
          />
          <span class="span1">大屏幕已断网</span>
          <span class="span2"
            >该屏幕被管理员设置成断网模式运行，暂时无法实时显示大屏正在播放的内容</span
          >
        </div>
      </div>
      <div
        v-else-if="aliveCurrentScreenName == 'demo'"
        :class="['video-view', aliveCurrentScreenName]"
        :style="'background-image: url(' + currentScreenInfo.image + ')'"
      >
        <video
          v-if="alive"
          oncontextmenu="return false;"
          ref="myFlvVideo"
          class="video"
          id="videoElement"
          disablePictureInPicture="true"
          style="object-fit: contain"
        ></video>
        <video
          v-else
          oncontextmenu="return false;"
          ref="myFlvVideo"
          class="video"
          id="videoElement"
          :src="currentMediaSrc"
          autoplay
          disablePictureInPicture="true"
          style="object-fit: contain"
          @ended="playNextVideo"
        ></video>
        <div class="voice-view" @click.stop="changeMute">
          <img v-if="videoMuted" class="icon" src="@/assets/img/mute.png" />
          <img v-else class="icon" src="@/assets/img/noMute.png" />
        </div>
        <div
          v-if="!skipFrameStatus"
          class="seat-view flex-col justify-center align-center"
        >
          <img src="../../assets/img/loading.gif" alt="" />
          <span>努力加载中…</span>
        </div>
        <div
          v-if="skipFrameStatus"
          class="skipFrameStatus flex-row justify-center align-center"
        >
          <img src="../../assets/img/loading.gif" alt="" />
        </div>
      </div>
      <div
        v-else
        :class="['video-view', aliveCurrentScreenName]"
        :style="'background-image: url(' + currentScreenInfo.image + ')'"
      >
        <video
          v-if="alive"
          oncontextmenu="return false;"
          ref="myFlvVideo"
          class="video"
          id="videoElement"
          disablePictureInPicture="true"
          style="object-fit: contain"
        ></video>
        <div v-if="alive" class="voice-view" @click.stop="changeMute">
          <img v-if="videoMuted" class="icon" src="@/assets/img/mute.png" />
          <img v-else class="icon" src="@/assets/img/noMute.png" />
        </div>
        <div
          v-if="!skipFrameStatus"
          class="seat-view flex-col justify-center align-center"
        >
          <img src="../../assets/img/loading.gif" alt="" />
          <span>努力加载中…</span>
        </div>
        <div
          v-if="skipFrameStatus"
          class="skipFrameStatus flex-row justify-center align-center"
        >
          <img src="../../assets/img/loading.gif" alt="" />
        </div>
      </div>
    </div>
    <div
      v-if="!isOpen && aliveScreenList.length"
      class="hlsVideo_close flwx-col"
    >
      <div class="screenList flex-row align-center">
        <div
          v-for="(item, index) in aliveScreenList"
          :key="index"
          :class="{
            screenItem: true,
            active:
              aliveCurrentScreenName == item.name ||
              (item.attachSub.length &&
              item.attachSub[0].name == aliveCurrentScreenName
                ? true
                : false),
          }"
          @click="changeAliveScreen(item.name)"
        >
          <span>{{ item.remark }}</span>
          <img
            v-if="
              aliveCurrentScreenName == aliveScreenList[0].name &&
              (aliveCurrentScreenName == item.name ||
                (item.attachSub.length &&
                item.attachSub[0].name == aliveCurrentScreenName
                  ? true
                  : false))
            "
            class="hlsVideo-tab1"
            src="@/assets/img/hlsVideo-tab1.png"
            alt=""
          />
          <img
            v-if="
              aliveCurrentScreenName != aliveScreenList[0].name &&
              (aliveCurrentScreenName == item.name ||
                (item.attachSub.length &&
                item.attachSub[0].name == aliveCurrentScreenName
                  ? true
                  : false))
            "
            class="hlsVideo-tab2"
            src="@/assets/img/hlsVideo-tab2.png"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="
          currentScreenInfo.isMain &&
          currentScreenInfo.attachSub &&
          currentScreenInfo.attachSub.length
        "
        @click="changeAliveScreen(currentScreenInfo.attachSub[0].name)"
        class="change-view justify-center align-center"
      >
        <img src="@/assets/icon/exchange.png" alt="" />
        <span>切至辅屏</span>
      </div>
      <div
        v-else-if="!currentScreenInfo.isMain"
        @click="changeAliveScreen(currentScreenInfo.attachMainName)"
        class="change-view justify-center align-center"
      >
        <img src="@/assets/icon/exchange.png" alt="" />
        <span>切至主屏</span>
      </div>
      <div v-else class="change-view justify-center align-center"></div>
      <div class="hlsVideo-title flex-row justify-between align-center">
        <div class="flex-left flex-row align-center">
          <img src="../../assets/img/shut_own.png" alt="" />
          <span v-if="currentScreenInfo.attachMainName"
            >{{
              currentScreenInfo.attachMainName | screenFilter
            }}（辅屏），大屏幕已关机</span
          >
          <span v-else
            >{{ currentScreenInfo.name | screenFilter }}，大屏幕已关机</span
          >
        </div>
        <div class="flex-row align-center">
          <div
            v-if="currentScreenInfo.video"
            class="popover"
            @click="onClick_see"
          >
            <div class="reference flex-row align-center">
              <svg-icon class="svg" iconClass="UAV"></svg-icon>
            </div>
          </div>
          <el-popover
            class="popover"
            placement="top"
            trigger="click"
            :visible-arrow="false"
            popper-class="homePopover2"
          >
            <div class="device-info flex-row flex-wrap">
              <div class="info-item flex-row align-center">
                <span class="label">设备名称：</span>
                <span class="value">{{ currentScreenInfo.remark }}</span>
              </div>
              <div class="info-item flex-row align-center">
                <span class="label">设备类型：</span>
                <span class="value">LED大屏</span>
              </div>
              <div class="info-item flex-row align-center">
                <span class="label">屏幕分辨率：</span>
                <span class="value"
                  >{{ currentScreenInfo.resolutionWidth }}*{{
                    currentScreenInfo.resolutionHeight
                  }}
                  (即{{ aspectRatioFn() }})</span
                >
              </div>
              <div class="info-item flex-row align-center">
                <span class="label">开机时间：</span>
                <span class="value">{{ openTimeFn() }}</span>
              </div>
              <div
                v-if="currentScreenInfo.peopleTraffic"
                class="info-item flex-row align-center"
              >
                <span class="label">人流量：</span>
                <span class="value">{{ currentScreenInfo.peopleTraffic }}</span>
              </div>
              <div class="info-item flex-row align-center">
                <span class="label">广告展现方式：</span>
                <span class="value">图片/视频/声音</span>
              </div>
              <div
                v-if="currentScreenInfo.carTraffic"
                class="info-item flex-row align-center"
              >
                <span class="label">车流量：</span>
                <span class="value">{{ currentScreenInfo.carTraffic }}</span>
              </div>
              <div
                v-if="currentScreenInfo.size"
                class="info-item flex-row align-center"
              >
                <span class="label">屏幕尺寸：</span>
                <span class="value">{{ currentScreenInfo.size }}</span>
              </div>
              <div
                v-if="currentScreenInfo.audience"
                class="info-item flex-row long-line"
              >
                <span class="label">受众人群：</span>
                <span class="value">{{ currentScreenInfo.audience }}</span>
              </div>
              <div
                v-if="currentScreenInfo.address"
                class="info-item flex-row long-line"
              >
                <span class="label">设备地址：</span>
                <span class="value">{{ currentScreenInfo.address }}</span>
              </div>
            </div>
            <div slot="reference" class="reference flex-row align-center">
              <svg-icon class="svg" iconClass="sigh"></svg-icon>
            </div>
          </el-popover>
        </div>
      </div>
      <div
        :class="['video-view', aliveCurrentScreenName]"
        :style="'background-image: url(' + currentScreenInfo.image + ')'"
      >
        <div class="screen-view flex-col justify-center align-center">
          <img src="../../assets/img/lamp.png" alt="" />
          <span class="span1">大屏幕运行时间：{{ strOpenTime }}</span>
          <span class="span2" v-if="currentScreenInfo.isMain"
            >目前大屏正处于关机状态，但您仍然可以投放广告，并在投放时预约广告投放时间</span
          >
          <span class="span2" v-else
            >目前大屏正处于关机状态，辅屏仅同步播放主屏广告，请以主屏播放状态为准</span
          >
        </div>
      </div>
    </div>
    <div
      v-if="adHistoryList && adHistoryList.length > 0"
      class="adList-view flex-col align-center"
    >
      <div class="adList-view-title flex-row justify-between align-center">
        <span class="title-left">投放案例</span>
        <!-- <div
          v-if="allAdHistoryListLength > 6"
          class="title-right flex-row align-center"
          @click="$router.push('/others/listAdHistory')"
        >
          <span class="txt">查看更多</span>
          <svg-icon class="svg" iconClass="arrow"></svg-icon>
        </div> -->
      </div>
      <div class="adList-list flex-row flex-wrap">
        <div
          class="adList-item flex-col"
          :style="isFirefox ? 'width:389rem' : ''"
          v-for="(item, index) in adHistoryList"
          :key="item.adId"
          @click.stop="handleClickSlide(item, index)"
        >
          <div class="videoThumbnailUrl flex-col">
            <img class="pic" :src="item.videoThumbnailUrl" />
            <img class="icon" src="@/assets/img/play_icon.png" />
          </div>
          <div class="releaseUser-view flex-row align-center">
            <span>投放者：</span>
            <img v-if="item.userImage" :src="item.userImage" alt="" />
            <img v-else src="@/assets/img/default_head.png" alt="" />
            <span>{{ item.userName }}</span>
          </div>
          <div class="release-beginUnix flex-row align-center">
            <span>最新投放时间：</span>
            <span>{{ item.beginUnix | dateFormat }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="advantage-view flex-col align-center">
      <span class="advantage-title">自助投放广告软件引领者</span>
      <span class="advantage-desc">快捷高效，无中间商赚差价</span>
      <div class="advantageList-view flex-row flex-wrap">
        <div
          class="advantage-item flex-col align-center"
          :style="isFirefox ? 'width:615rem' : ''"
        >
          <img src="@/assets/img/homePic1.png" alt="" />
          <span class="txt1">在线下单</span>
          <span class="txt2"
            >在线投放广告，整个流程没有人工干预。订单审核通过后，系统会按照订单的指令自动播放广告视频。<a
              href="https://www.daping.cn/share?code=dd8277a6edc54c2ba306fed9489c70a2"
              target="_blank"
              >查看订单分享示例</a
            ></span
          >
        </div>
        <div
          class="advantage-item flex-col align-center"
          :style="isFirefox ? 'width:615rem' : ''"
        >
          <img src="@/assets/img/homePic2_s.png" alt="" />
          <span class="txt1">小程序投放</span>
          <span class="txt2"
            >用户可以通过“大屏时代极速版”微信小程序进行广告投放，随时随地提交投放订单，操作简单、方便快捷。</span
          >
        </div>
        <div
          class="advantage-item flex-col align-center"
          :style="isFirefox ? 'width:615rem' : ''"
        >
          <img src="@/assets/img/homePic3.png" alt="" />
          <span class="txt1">精确投放</span>
          <span class="txt2"
            >可以根据自己的需求，自主预约广告播出的时间。自主选择哪个LED大屏进行广告投放。</span
          >
        </div>
        <div
          class="advantage-item flex-col align-center"
          :style="isFirefox ? 'width:615rem' : ''"
        >
          <img src="@/assets/img/homePic4.png" alt="" />
          <span class="txt1">精准监播</span>
          <span class="txt2"
            >系统会列出您的每一次播放时间，您可以去现场查看；也可以远程查看大屏监控。<a
              href="https://www.daping.cn/p2pVisitor/"
              target="_blank"
              >查看大屏监控示例</a
            ></span
          >
        </div>
      </div>
    </div>
    <home-pre-video-dialog
      ref="refPreVideoDialog"
      :videoData="preVideoData"
      :length="adHistoryList.length"
      @dialogPrev="dialogPrev"
      @dialogLower="dialogLower"
    ></home-pre-video-dialog>
    <pre-video-dialog
      ref="refPreVideo"
      :videoData="preVideoData2"
    ></pre-video-dialog>
  </div>
</template>
<script>
/* eslint-disable import/no-cycle */

import bus from "@/utils/bus";
import {
  getListAdHistory,
  getLiveAddress,
  getConfig,
  getListDaping,
} from "@/api/request";
import Flvjs from "flv.js";
import preVideoDialog from "@/components/preVideoDialog.vue";
import homePreVideoDialog from "@/components/homePreVideoDialog.vue";

export default {
  components: {
    preVideoDialog,
    homePreVideoDialog,
  },
  data() {
    return {
      mediaList: [
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo1.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo2.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo3.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo4.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo5.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo6.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo7.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo8.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo9.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo10.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo11.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo12.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo13.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo14.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo15.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo16.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo17.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo18.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo19.mp4",
        "https://void-daping.oss-cn-shenzhen.aliyuncs.com/resource/video/investment/investmentVideo20.mp4"
      ],
      currentMediaSrc: "",
      currentVideoIndex: Math.floor(Math.random() * 21), // 0到20之间的随机整数
      flvPlayer: null,
      httpFlvAddress: "",
      alive: null, // 是否正在推流
      getConfigTimer: null, // 初始定时器变量名为null
      token: localStorage.getItem("token") || "",
      adListCurrentIndex: 0,
      // allAdHistoryListLength: 0,
      allAdHistoryListLength: 6,
      preVideoData: {
        dialogUrl: "",
        dialogTitle: "",
        beginUnix: null,
        currentScreen: "",
      },
      preVideoData2: {},
      beginUnix: "",
      adHistoryList: [
        {
          name: 'yihua',
          videoUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/joe.chen/pre_check_video/42ae27a686d37159c5db34f86916b685.mp4',
          videoThumbnailUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/joe.chen/pre_check_video/42ae27a686d37159c5db34f86916b685_8d2eff4da753cea4b94d15003b77bdec.jpeg',
          userImage: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/joe.chen/fb84a7ce4a7447078278f0e55f9f09ad-Black%2520White%2520Minimalist%2520Aesthetic%2520Letter%2520Initial%2520Name%2520Monogram%2520Logo.png',
          userName: '早安肇庆',
          beginUnix: '1704067416',
        },
        {
          name: 'yihua',
          videoUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/U336574753417734/2856a6c73a8972861b1702a46b0c2acc.mp4',
          videoThumbnailUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/U336574753417734/a0d11c8decfe458bb580a5dbeac897de.jpeg',
          userImage: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/avatar/U336574753417734.png',
          userName: '大屏会员_559',
          beginUnix: '1683936072',
        },
        {
          name: 'yihua',
          videoUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/U312291588059884/8d265e20eb9576f07d0a0edbd418c170.mp4',
          videoThumbnailUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/U312291588059884/aa68ed1e81f52c4beaeafe5243d1b984.jpeg',
          userImage: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/U312291588059884/upload/5521ff32daec4a19b3f9d51ce1b8c3b7-20240128%25E6%25BB%25A8%25E6%25B1%259F%25E5%2598%2589%25E8%258B%2591%2520-%25E5%25B7%25B2%25E7%25AB%25A3%25E5%25A4%2587%25E9%25AA%258C%25E6%2594%25B6-%25E5%25B0%2581%25E9%259D%25A2.jpg',
          userName: '滨江嘉苑',
          beginUnix: '1678595436',
        },
        {
          name: 'default',
          videoUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/63ea0db049c94e92b87c12a9512600e8/fe3072c60691c718d7860423320a049e.mp4',
          videoThumbnailUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/63ea0db049c94e92b87c12a9512600e8/b5a2c84d811249ef401bab40600ebfc0.jpeg',
          userImage: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/63ea0db049c94e92b87c12a9512600e8/cb3061cffb7043419c52085fe4a14ae0-%25E8%25BF%25AA%25E7%259B%259B%2520%25E6%25AF%2594%25E4%25BA%259A%25E8%25BF%25AA.jpg',
          userName: '肇庆迪盛-比亚迪',
          beginUnix: '1665047484',
        },
        {
          name: 'yihua',
          videoUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/joe.chen/pre_check_video/c48ea173871ab30414ffc206941b68fb.mp4',
          videoThumbnailUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/joe.chen/cover/e572d5909548fd41d774e1caa085df39.jpg',
          userImage: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/joe.chen/fb84a7ce4a7447078278f0e55f9f09ad-Black%2520White%2520Minimalist%2520Aesthetic%2520Letter%2520Initial%2520Name%2520Monogram%2520Logo.png',
          userName: '早安肇庆',
          beginUnix: '1695880968',
        },
        {
          name: 'yihua',
          videoUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/joe.chen/pre_check_video/45b48a2cce594ee3266729ed8429fa66.mp4',
          videoThumbnailUrl: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/joe.chen/pre_check_video/45b48a2cce594ee3266729ed8429fa66_91486d0c846d13a4fc5efdee8c8fb067.jpeg',
          userImage: 'https://void-daping.oss-cn-shenzhen.aliyuncs.com/daping/joe.chen/fb84a7ce4a7447078278f0e55f9f09ad-Black%2520White%2520Minimalist%2520Aesthetic%2520Letter%2520Initial%2520Name%2520Monogram%2520Logo.png',
          userName: '早安肇庆',
          beginUnix: '1702880820',
        },
      ],
      isOpen: true, // 大屏是否处于开机状态
      isOff: false, // 大屏是否处于断网状态
      isCloseScreen: false, // 大屏是否处于手动关机状态
      strOpenTime: "", // 大屏开机时间
      todayOpenTimeDifference: 0, // 大屏开机时间差（时间戳的差值）
      skipFrameStatus: false, // 手动跳帧动画
      lastDecodedFrame: 0,
      breakNum: 0, // 初始化断流次数
      aliveCurrentScreenName: "", // 大屏直播初始化默认屏幕
      videoMuted: true, // 拉流是否静音状态
      isFirefox: false, // 是否是火狐浏览器
      aliveScreenList: [], // 正常屏幕列表（无下架）
    };
  },
  created() {
    // 初始化正常屏幕（无下架）列表
    this.initAliveScreenList();
  },
  mounted() {
    // 异步请求模板和投放历史
    // setTimeout(() => {
    //   this.getListAdHistory();
    // }, 10);
    // bus监听断网状态
    bus.$on("OfflineStatus", () => {
      this.alive = false;
    });
    // 直播静音
    bus.$on("enableMute", () => {
      this.enableMute();
    });
    // 如果是火狐浏览器，解决样式多1px不兼容的问题
    if (navigator.userAgent.indexOf("Firefox") > -1) {
      this.isFirefox = true;
    }
  },
  computed: {
    newDiscountStatus() {
      return this.$store.state.newDiscountStatus;
    },
    currentScreenInfo() {
      let findV = "";
      findV = this.aliveScreenList.find(
        (v) => v.name == this.aliveCurrentScreenName
      );
      // 如果主屏信息找不到
      if (!findV) {
        let findV2 = "";
        this.aliveScreenList.forEach((item) => {
          if (item.attachSub.length > 0) {
            if (
              item.attachSub.find((v) => v.name == this.aliveCurrentScreenName)
            ) {
              findV2 = item.attachSub.find(
                (v) => v.name == this.aliveCurrentScreenName
              );
            }
          }
        });
        if (findV2) {
          findV = findV2;
        }
      }
      return findV;
    },
    hostname() {
      return window.location.hostname;
    },
  },
  watch: {
    alive(newVal) {
      // 如果没有直播
      if (!newVal) {
        if (!this.flvPlayer) return;
        this.destoryVideo();
      }
    },
  },
  methods: {
    // 初始化正常屏幕（无下架）列表
    initAliveScreenList() {
      if (this.$store.state.screenList.length) {
        // 过滤已下架（down为true）的屏幕
        const currentScreenList = this.$store.state.screenList.filter(
          (v) => !v.down
        );
        this.aliveScreenList = currentScreenList;
        // 如果存在大屏（大屏没有下架）
        if (this.aliveScreenList.length) {
          this.aliveCurrentScreenName = this.aliveScreenList[0].name;
          // 定时器获取大屏是否在营业时间段
          this.getConfigInterval();
          this.getConfigTimer = setInterval(() => {
            this.getConfigInterval();
          }, 3000);
        }
      } else {
        // 假如获取不到store里面的屏幕数据，重新请求接口拿数据
        this.getDapingList();
      }
    },
    getDapingList() {
      getListDaping({}).then((res) => {
        this.$store.dispatch("screenListActions", res.list);
        if (res.list.length) {
          // 过滤已下架（down为true）的屏幕
          const currentScreenList = res.list.filter((v) => !v.down);
          this.aliveScreenList = currentScreenList;
          // 如果存在大屏（大屏没有下架）
          if (this.aliveScreenList.length) {
            this.aliveCurrentScreenName = this.aliveScreenList[0].name;
            // 定时器获取大屏是否在营业时间段
            this.getConfigInterval();
            this.getConfigTimer = setInterval(() => {
              this.getConfigInterval();
            }, 3000);
          }
        }
      });
    },
    // 获取大屏是否在营业时间段
    getConfigInterval() {
      getConfig({
        name: this.aliveCurrentScreenName,
      }).then((res) => {
        this.isOpen = res.isOpen;
        this.isOff = res.isOff;
        this.isCloseScreen = res.isCloseScreen;
        this.strOpenTime = res.strOpenTime;
        this.todayOpenTimeDifference = res.todayOpenEnd - res.todayOpenBegin;
        // 如果是开机状态，则定时器请求获取直播源
        if (res.isOpen) {
          this.getLiveAddress();
        }
      });
    },
    // 获取直播数据
    getLiveAddress() {
      getLiveAddress({
        name: this.aliveCurrentScreenName,
        sub_name: "2",
      }).then((res) => {
        // alive表示是否正在直播中（推流中）
        this.alive = res.alive;
        if (
          !res.alive &&
          this.aliveCurrentScreenName == "demo" &&
          !this.currentMediaSrc
        ) {
          const videoPlayer = document.getElementById("videoElement");
          videoPlayer.muted = true;
          this.videoMuted = true;
          this.playNextVideo();
          return;
        }
        if (!res.alive) return;
        if (this.flvPlayer) return;
        this.currentMediaSrc = "";
        this.httpFlvAddress = res.httpFlvAddress;
        this.$nextTick(() => {
          this.createVideo();
        });
      });
    },
    playNextVideo() {
      this.currentMediaSrc = this.mediaList[this.currentVideoIndex];
      this.currentVideoIndex =
        (this.currentVideoIndex + 1) % this.mediaList.length;
    },
    // 获取历史投放
    getListAdHistory() {
      getListAdHistory({
        pageLimit: {
          limit: 6,
          page: 1,
        },
        name: "",
      }).then((res) => {
        // 拉到数据后，重置swiper
        this.adHistoryList = [];
        if (res.adHistoryList.length == 0) {
          return;
        }
        // this.allAdHistoryListLength = res.adHistoryList.length;
        // this.adHistoryList = res.adHistoryList.slice(0, 6);
        this.allAdHistoryListLength = res.pageResult.total;
        this.adHistoryList = res.adHistoryList;
      });
    },
    // 播放
    createVideo() {
      if (Flvjs.isSupported()) {
        console.log("开始连接视频...");
        const videoElement = this.$refs.myFlvVideo;
        // 判断是否Safari浏览器
        // if ((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))) {
        //   videoElement.style = 'object-fit: cover';
        // } else {
        //   videoElement.style = 'object-fit: fill';
        // }
        videoElement.muted = true;
        this.videoMuted = true;
        this.flvPlayer = Flvjs.createPlayer(
          {
            type: "flv",
            isLive: true, // 实时流
            hasAudio: true, // 是否拉取音频
            url: this.httpFlvAddress,
          },
          {
            cors: true, // 是否跨域
            enableStashBuffer: false, // 是否启用缓存
            autoCleanupSourceBuffer: true, // 是否自动清理缓存
          }
        );
        this.flvPlayer.attachMediaElement(videoElement);
        this.flvPlayer.load();
        console.log("开始播放视频...");
        this.flvPlayer.play();
        // 定时方法是为了用户离开页面视频是实时播放的,暂停按钮无用
        setInterval(() => {
          // console.log(videoElement.buffered,"idididid");
          if (videoElement.buffered.length > 0) {
            const end = videoElement.buffered.end(0); // 视频结尾时间
            const current = videoElement.currentTime; //  视频当前时间
            const diff = end - current; // 相差时间
            // console.log(diff);
            const diffCritical = 4; // 这里设定了超过4秒以上就进行跳转
            const diffSpeedUp = 1; // 这里设置了超过1秒以上则进行视频加速播放
            const maxPlaybackRate = 4; // 自定义设置允许的最大播放速度
            let playbackRate = 1.0; // 播放速度
            if (diff > diffCritical) {
              //  this.flvPlayer.load();
              // console.log("相差超过4秒，进行跳转");
              videoElement.currentTime = end - 1.5;
              playbackRate = Math.max(1, Math.min(diffCritical, 16));
            } else if (diff > diffSpeedUp) {
              // console.log("相差超过1秒，进行加速");
              playbackRate = Math.max(1, Math.min(diff, maxPlaybackRate, 16));
            }
            videoElement.playbackRate = playbackRate;
            if (videoElement.paused) {
              videoElement.play();
            }
          }
          // flv视频流延迟手动跳帧
          if (videoElement.buffered.length) {
            const end = this.flvPlayer.buffered.end(0); // 获取当前buffered值
            const diff = end - this.flvPlayer.currentTime; // 获取buffered与currentTime的差值
            // console.log(diff);
            // 如果diff跳帧太大，则显示加载动画
            if (diff >= 2) {
              this.skipFrameStatus = true;
            } else {
              this.skipFrameStatus = false;
            }
            if (diff >= 1) {
              // 如果差值大于等于1 手动跳帧 这里可根据自身需求来定,手动跳帧卡顿的情况可以尝试把获取当前buffered值-0.5
              this.flvPlayer.currentTime = this.flvPlayer.buffered.end(0) - 0.5;
            }
          }
        }, 1000);
        // 报错重连
        this.flvPlayer.on(
          Flvjs.Events.ERROR,
          (errType, errDetail, errorInfo) => {
            console.log("errorType:", errType);
            console.log("errorDetail:", errDetail);
            console.log("errorInfo:", errorInfo);
            if (this.flvPlayer) {
              console.log("播放出错了，准备销毁视频...");
              this.destoryVideo();
              // const { userAgent } = navigator;
              // if (userAgent.indexOf("Firefox") > -1) {
              //   // 如果是火狐浏览器，不要执行createVideo操作，因为火狐浏览器有点sb
              //   return;
              // }
              // this.createVideo();
            }
          }
        );
        // 断流重连
        this.flvPlayer.on("statistics_info", (res) => {
          // console.log(this.lastDecodedFrame, res.decodedFrames);
          if (this.lastDecodedFrame == 0) {
            this.lastDecodedFrame = res.decodedFrames;
            return;
          }
          if (this.lastDecodedFrame != res.decodedFrames) {
            this.lastDecodedFrame = res.decodedFrames;
            this.breakNum = 0;
          } else if (
            this.lastDecodedFrame == res.decodedFrames &&
            this.breakNum < 6
          ) {
            this.lastDecodedFrame = res.decodedFrames;
            this.breakNum += 1;
            console.log("已断流", this.breakNum, "次...");
          } else {
            console.log("已断流6次，准备销毁视频...");
            this.lastDecodedFrame = 0;
            this.breakNum = 0;
            this.destoryVideo();
            // const { userAgent } = navigator;
            // if (userAgent.indexOf("Firefox") > -1) {
            //   // 如果是火狐浏览器，不要执行createVideo操作，因为火狐浏览器有点sb
            //   return;
            // }
            // this.createVideo();
          }
        });
      } else {
        this.$message({
          message: "您的浏览器不支持flv格式视频",
          type: "warning",
          center: true,
        });
      }
    },
    // 停止/销毁播放
    destoryVideo() {
      if (this.flvPlayer) {
        this.flvPlayer.pause();
        this.flvPlayer.unload();
        this.flvPlayer.detachMediaElement();
        this.flvPlayer.destroy();
        this.flvPlayer = null;
        this.skipFrameStatus = false;
      }
    },
    // 直播关闭声音
    enableMute() {
      if (this.alive && this.isOpen && this.flvPlayer) {
        const videoElement = this.$refs.myFlvVideo;
        videoElement.muted = true;
        this.videoMuted = true;
      }
    },
    // 静音/取消静音
    changeMute() {
      const videoElement = this.$refs.myFlvVideo;
      videoElement.muted = !videoElement.muted;
      this.videoMuted = videoElement.muted;
    },
    // 点击单个历史投放广告
    handleClickSlide(item, index) {
      this.enableMute(); // 直播静音
      this.adListCurrentIndex = index;
      this.preVideoData.dialogUrl = `${item.videoUrl}?${new Date().getTime()}`;
      this.preVideoData.dialogTitle = item.userName;
      this.preVideoData.beginUnix = item.beginUnix;
      this.preVideoData.currentScreen = item.name;
      this.$refs.refPreVideoDialog.openDialog();
    },
    // 点击上一个
    dialogPrev() {
      let prevIndex = null;
      if (this.adListCurrentIndex == 0) {
        prevIndex = this.adHistoryList.length - 1;
      } else {
        prevIndex = this.adListCurrentIndex - 1;
      }
      this.preVideoData.dialogUrl = `${
        this.adHistoryList[prevIndex].videoUrl
      }?${new Date().getTime()}`;
      this.preVideoData.dialogTitle = this.adHistoryList[prevIndex].userName;
      this.preVideoData.beginUnix = this.adHistoryList[prevIndex].beginUnix;
      this.preVideoData.currentScreen = this.adHistoryList[prevIndex].name;
      this.adListCurrentIndex = prevIndex;
    },
    // 点击下一个
    dialogLower() {
      let lowerIndex = null;
      if (this.adListCurrentIndex == this.adHistoryList.length - 1) {
        lowerIndex = 0;
      } else {
        lowerIndex = this.adListCurrentIndex + 1;
      }
      this.preVideoData.dialogUrl = `${
        this.adHistoryList[lowerIndex].videoUrl
      }?${new Date().getTime()}`;
      this.preVideoData.dialogTitle = this.adHistoryList[lowerIndex].userName;
      this.preVideoData.beginUnix = this.adHistoryList[lowerIndex].beginUnix;
      this.preVideoData.currentScreen = this.adHistoryList[lowerIndex].name;
      this.adListCurrentIndex = lowerIndex;
    },
    onClick_create() {
      this.$canvaUtil.onClickCreate();
    },
    // 切换直播屏幕
    changeAliveScreen(name) {
      if (this.aliveCurrentScreenName == name) return;
      this.alive = false;
      this.currentMediaSrc = "";
      // 关机状态不需要销毁直播视频
      if (this.isOpen) {
        this.destoryVideo();
      }
      this.aliveCurrentScreenName = name;
      this.getConfigInterval();
    },
    clearGetConfigTimer() {
      if (this.getConfigTimer) {
        clearInterval(this.getConfigTimer);
        this.getConfigTimer = null;
      }
    },
    // goWhyPage
    goWhyPage() {
      this.$router.push({ name: "Launch" });
    },
    // 计算开机时间
    openTimeFn() {
      if (this.todayOpenTimeDifference) {
        let days = this.todayOpenTimeDifference / 3600;
        if (!Number.isInteger(days)) {
          days = Math.floor(days * 10) / 10;
        }
        return `${this.strOpenTime}（${days}小时/天）`;
      }
      return "";
    },
    // 计算宽高比
    aspectRatioFn() {
      const Width = this.currentScreenInfo.resolutionWidth;
      const Height = this.currentScreenInfo.resolutionHeight;
      const maxNumber = this.maxNumber(Width, Height); // 宽和高的最大公约数
      return `${Width / maxNumber} : ${Height / maxNumber}`;
    },
    // 计算最大公约数
    maxNumber(a, b) {
      if (a > 0 && b > 0) {
        while (b !== 0) {
          const temp = b;
          b = a % b;
          a = temp;
        }
        return a;
      }
      return 1;
    },
    onClick_see() {
      const videoURL = this.currentScreenInfo.video;
      this.preVideoData2 = {
        dialogUrl: videoURL,
        dialogTitle: "航拍地段",
        muted: true,
      };
      setTimeout(() => {
        this.$refs.refPreVideo.openDialog();
      }, 100);
    },
    onClick_p2p() {
      window.open("https://www.daping.cn/p2pVisitor/", "_blank");
    },
  },
  beforeDestroy() {
    this.clearGetConfigTimer();
    this.destoryVideo();
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
// //全屏按钮
// #videoElement::-webkit-media-controls-fullscreen-button {
//   display: none !important;
// }
// //播放按钮
// #videoElement::-webkit-media-controls-play-button {
//   display: none !important;
// }
// //进度条
// #videoElement::-webkit-media-controls-timeline {
//   display: none !important;
// }
// //观看的当前时间
// #videoElement::-webkit-media-controls-current-time-display {
//   display: none !important;
// }
// //剩余时间
// #videoElement::-webkit-media-controls-time-remaining-display {
//   display: none !important;
// }
</style>
